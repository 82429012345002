import Link from 'next/link';
import Image from "next/legacy/image";

interface GNOFooterProps {}

const GNOFooter: React.FC<GNOFooterProps> = () => {
  return (
    <footer className="px-6 py-4 bg-careerPageHeading md:p-2">
      <section className="container mx-auto">
        <nav className="flex flex-col justify-between w-full pb-6 border-solid md:flex-row border-b-1 border-white/50 md:pb-0">
          <Link
            href="/careerguide"
            className="py-2 text-base font-semibold text-center text-white font-base hover:text-gray8"
          >
            Browse Careers
          </Link>
          <Link
            href="/training"
            className="py-2 text-base font-semibold text-center text-white font-base hover:text-gray8"
          >
            Browse Trainings
          </Link>
          <Link
            target="_blank"
            href="https://gnoresourcehub.wherewego.org"
            className="py-2 text-base font-semibold text-center text-white font-base hover:text-gray8"
          >
            Browse Resources
          </Link>
          <Link
            className="py-2 text-base font-semibold text-center text-white font-base hover:text-gray8"
            href="/careerguide/quiz"
          >
            Take Our Short Quiz
          </Link>
        </nav>
        <section className="grid grid-cols-1 py-4 md:grid-cols-3 md:gap-10 lg:gap-20 md:py-0">
          <div className="grid items-center md:py-10 grid-rows-gnoIncFooter">
            <div className="w-[200px] mx-auto">
              <Image
                src="https://res.cloudinary.com/wherewego/image/upload/v1657777152/WWG2000%20Site%20Photos/cuioglmetpdle8qqgpmw.png"
                blurDataURL="https://res.cloudinary.com/wherewego/image/upload/v1657777152/WWG2000%20Site%20Photos/cuioglmetpdle8qqgpmw.png"
                placeholder="blur"
                loading="eager"
                layout="responsive"
                width={2500}
                height={500}
                alt="GNO Logo"
              />
            </div>
            <p className="py-4 text-base text-center text-white lg:text-sm md:text-xs md:text-left">
              Greater New Orleans, Inc. is the regional economic development
              nonprofit organization serving the 10-parish region of Southwest
              Louisiana.
            </p>
            <div className="flex flex-col w-full py-2 md:flex-row">
              <div className="flex-1">
                <p className="text-base text-center text-white/60 lg:text-sm md:text-xs md:text-left">
                  1100 Poydras Street, Suite 3475
                </p>
                <p className="text-base text-center text-white/60 lg:text-sm md:text-xs md:text-left">
                  New Orleans, LA 70163{' '}
                </p>
                <p className="text-base text-center text-white/60 lg:text-sm md:text-xs md:text-left">
                  <Link href="tel:5045276900">(504) 527 - 6900</Link>
                </p>
              </div>
              <div className="items-center flex-1">
                <Link
                  className="block w-full my-4 font-semibold text-center text-white underline md:my-0 hover:text-gray8"
                  target="_blank"
                  href="https://gnoinc.org/about"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="grid items-center md:py-10 grid-rows-gnoIncFooter">
            <div className="w-[200px] mx-auto">
              <Image
                src="https://res.cloudinary.com/wherewego/image/upload/v1674576709/WWG2000%20Site%20Photos/dvwgvcteemrqgkcf2msq.png"
                loading="eager"
                layout="responsive"
                width={2700}
                height={500}
                alt="WhereWeGo Logo"
              />
            </div>
            <p className="py-4 text-base text-center text-white lg:text-sm md:text-xs md:text-left">
              WhereWeGo is an EdTech and Design Thinking company based in New
              Orleans that increases access to careers, credentials, and
              credits.
            </p>
            <div className="flex flex-col self-start w-full py-2 md:flex-row">
              <div className="flex-1">
                <Link
                  className="block w-full my-2 font-semibold text-center text-white underline md:text-left hover:text-gray8 md:my-0"
                  target="_blank"
                  href="mailto:info@wherewego.org"
                >
                  info@wherewego.org
                </Link>
              </div>
              <div className="flex-1">
                <Link
                  className="block w-full font-semibold text-center text-white underline hover:text-gray8"
                  target="_blank"
                  href="https://wherewego.org"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="grid items-center md:py-10 grid-rows-gnoIncFooter justify-items-center">
            <div className="w-full">
              <Image
                className="object-contain"
                src="https://res.cloudinary.com/wherewego/image/upload/v1657777144/WWG2000%20Site%20Photos/tlyvtoqg6p94ct7x8flc.png"
                blurDataURL="https://res.cloudinary.com/wherewego/image/upload/v1657777144/WWG2000%20Site%20Photos/tlyvtoqg6p94ct7x8flc.png"
                placeholder="blur"
                loading="eager"
                layout="responsive"
                width={1500}
                height={200}
                alt="Chrevron Logo"
              />
            </div>
            <p className="text-center text-white">
              Powered by <br /> Chevron
            </p>
            <p className="text-center text-white/60">
              Built by{' '}
              <Link
                className="underline hover:text-gray8"
                target="_blank"
                href={'https://wherewego.org'}
              >
                WhereWeGo
              </Link>{' '}
              © 2023
            </p>
          </div>
        </section>
      </section>
    </footer>
  );
};

export default GNOFooter;
