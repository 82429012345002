import { IconProps } from '../../../lib/types';

const Menu: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
  );
};

export default Menu;
