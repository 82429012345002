import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { AuthType } from '../../../lib/enums';
import { useLoginRoute } from '../../../lib/hooks/loginRoute';
import { useAuth } from '../../../lib/providers/authProvider';
import { useAuthType } from '../../../lib/providers/authTypeState';
import Menu from '../../icons/Menu';
import UserIcon from '../../icons/User';
import Image from 'next/legacy/image';

interface GNOHeaderProps {}

const GNOHeader: React.FC<GNOHeaderProps> = () => {
  const { seeker } = useAuth();
  const { setAuthType } = useAuthType();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const router = useRouter();
  useLoginRoute(router);

  return (
    <header>
      <div className="container px-8 py-4 mx-auto">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center w-full justify-bewteen">
            <a
              href="https://gnocareerguide.org"
              className="w-3/4 lg:max-w-[500px]"
            >
              <Image
                src="https://res.cloudinary.com/wherewego/image/upload/v1674576479/WWG2000%20Site%20Photos/h8nurojtz39j3vjqtmbo.png"
                alt="GNO. Inc, WhereWeGo Louisiana CareerGuide"
                className="w-3/4 lg:w-[500px] object-contain mx-0 block"
                layout="responsive"
                loading="eager"
                width={5000}
                height={500}
              />
            </a>
            <div data-testid="menu" className="ml-12 md:ml-32">
              <Menu
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen);
                }}
                className="block w-6 h-6 ml-auto lg:hidden stroke-careerPageHeading"
              />
            </div>
          </div>

          <nav
            className={`max-w-full flex-1 lg:flex-none mx-2 lg:py-0 py-4 z-20 ${
              menuIsOpen ? 'block' : 'lg:block hidden'
            }`}
          >
            <ul
              className={`flex flex-col gap-4 lg:flex-row lg:items-center justify-between lg:relative absolute left-0 right-0 bg-white lg:shadow-none shadow-sm lg:px-0 lg:py-0 py-4 px-8 ${
                menuIsOpen ? 'flex' : 'lg:flex hidden'
              }`}
            >
              <li className="p-2 text-lg font-semibold text-careerPageHeading lg:p-0 hover:text-gray8">
                <Link href="/careerguide">Careers</Link>
              </li>
              <li className="p-2 text-lg font-semibold text-careerPageHeading lg:p-0 hover:text-gray8">
                <Link href="/training">Trainings</Link>
              </li>
              <li className="p-2 text-lg font-semibold text-careerPageHeading lg:p-0 hover:text-gray8">
                <Link
                  target="_blank"
                  href="https://gnoresourcehub.wherewego.org"
                >
                  Resources
                </Link>
              </li>
              {!seeker ? (
                <li className="p-2 text-lg font-semibold cursor-pointer text-careerPageHeading lg:p-0 hover:text-gray8">
                  <button
                    tabIndex={0}
                    onClick={() => setAuthType(AuthType.Register)}
                    className="font-bold"
                    data-testid="login"
                  >
                    Sign Up
                  </button>
                </li>
              ) : (
                <Link href="/profile">
                  <div
                    data-testid="profile-header-link"
                    className="flex items-center my-4 cursor-pointer"
                  >
                    <UserIcon className="w-6 h-6 mr-2 stroke-careerPageHeading" />
                    <p className="text-lg font-semibold text-careerPageHeading">
                      {seeker.name}
                    </p>
                  </div>
                </Link>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default GNOHeader;
