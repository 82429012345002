import { NextRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import { AuthType } from '../enums';
import { useAuthType } from '../providers/authTypeState';

export const useLoginRoute = (router: NextRouter) => {
  const { setAuthType } = useAuthType();
  // Use pathname to initialize AuthType state
  useEffect(() => {
    if (router.route.includes('/login')) {
      setAuthType(AuthType.Login);
      router.pathname = router.pathname.replace('/login', '');
      router.replace(router, undefined, { scroll: false });
      return;
    }

    if (router.route.includes('/sign-up')) {
      setAuthType(AuthType.Register);
      router.pathname = router.pathname.replace('/sign-up', '');
      router.replace(router, undefined, { scroll: false });
      return;
    }

    if (router.route.includes('/reset-password')) {
      setAuthType(AuthType.ResetPassword);
      router.pathname = router.pathname.replace('/reset-password', '');
      router.replace(router, undefined, { scroll: false });
      return;
    }
  }, [router]);
};
